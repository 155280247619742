import Vue from 'vue'
import Vuex from 'vuex'

import admin from './modules/admin'
import colors from './modules/colors'
import copy from './modules/copy'
import header from './modules/header'
import logo from './modules/logo'
import main from './modules/main'
import mainmtr from './modules/mainmtr'
import profile from './modules/profile'
import usuarios from './modules/usuarios'
import projects from './modules/projects'
import clase from './modules/clase'

import client from './modules/client'
import plans from './modules/plans'
import info from './modules/info'
import suscripciones from './modules/suscripciones'
import category from './modules/category'
import pasarela from './modules/pasarela'



Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    admin: admin,
    colors:colors,
    copy:copy,
    header:header,
    logo:logo,
    main: main,
    mainmtr:mainmtr,
    profile:profile,
    usuarios: usuarios,
    projects: projects,
    clase: clase,
    client: client,
    plans: plans,
    info: info,
    suscripciones: suscripciones,
    category:category,
    pasarela:pasarela,
  }
})
