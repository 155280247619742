"use strict";

import api from "@/api/api";
import userService from "../../services/user.service";
import ncryptService from "../../services/ncrypt.service";
import { CONSTANTS } from "../../global/index"
const { GETTING_INFO, 
    GET_INFO_SUCCESS, 
    GETTING_IMG, 
    GET_IMG_SUCCESS, 
    SET_HISTORY_OPTION, 
    SET_ADDED,SET_STATUS, 
    GETTING_MSG,GET_MSG,
    ERROR_MSG,
    ERROR_STATUS
 } = CONSTANTS;
 const ERROR_OBJ = {
  status: ERROR_STATUS,
  message: ERROR_MSG,
  result: "",
}
export default {
  namespaced: true,
  state: {
    loading: false,
    data: [],
    loading_img: false,
    img: null,
    historyOption: "Default",
    historyIndex: 0,
    status: "",
    msg: "Ver Todos",
    added: "",
  },
  getters: {
    loading(state) {
      return state.loading;
    },
    data(state) {
      return state.data;
    },
    loading_img(state) {
      return state.loading_img;
    },
    img(state) {
      return state.img;
    },
    getHistoryOption(state) {
      return state.historyOption;
    },
    getHistoryIndex(state) {
      return state.historyIndex;
    },
    getAdded(state) {
      return state.added;
    },
    status(state) {
      return state.status;
    },
    msg(state) {
      return state.msg;
    },
  },
  mutations: {
    [GETTING_INFO](state) {
      state.loading = true;
      state.data = [];
    },
    [GET_INFO_SUCCESS](state, change) {
      state.loading = false;
      state.data = change;
    },
    [GETTING_IMG](state) {
      state.loading_img = true;
      state.img = null;
    },
    [GET_IMG_SUCCESS](state, change) {
      state.loading_img = false;
      state.img = change;
    },
    [SET_HISTORY_OPTION](state, change) {
      state.historyOption = change;
    },
    [SET_ADDED](state, change) {
      state.added = change;
    },
    [SET_STATUS](state, value) {
      state.status = value;
    },
    [GET_MSG](state, value) {
      state.msg = value;
    },
    [GETTING_MSG](state) {
      state.msg = "Cargando...";
    },
  },
  actions: {
    setAddedPln({ commit }, payload) {
      commit(SET_ADDED, payload);
    },

    setHistoryOptionPln({ commit }, payload) {
      commit(SET_HISTORY_OPTION, payload);
    },
    
    async getAllInfoPln({ commit }, payload) {
      commit(GETTING_INFO);
      commit(GETTING_MSG);

      try {
        const token = userService.getToken();
        const _pk = ncryptService._createPubToken();
        const headers = { Authorization: token, _pk };
        const response = await api.getAllInfo(payload, headers);
        commit(GET_INFO_SUCCESS, response.data.result);

        return response.data;
      } catch (error) {
        return ERROR_OBJ;
      }
    },

    async getInfoByIdPln({ commit }, payload) {
      commit(GETTING_INFO);

      try {
        const token = userService.getToken();
        const _pk = ncryptService._createPubToken();
        const headers = { Authorization: token, _pk };
        const response = await api.getInfoById(payload, headers);
        commit(GET_INFO_SUCCESS, response.data.result);

        return response.data.result;
      } catch (error) {
        return ERROR_OBJ;
      }
    },

    async getByDatePln({ commit }, payload) {
      commit(GETTING_INFO);

      try {
        const token = userService.getToken();
        const _pk = ncryptService._createPubToken();
        const headers = { Authorization: token, _pk };
        const response = await api.getByDate(payload, headers);
        commit(GET_INFO_SUCCESS, response.data.result);

        return response.data.result;
      } catch (error) {
        return ERROR_OBJ;
      }
    },

    async deleteItemPln({ commit }, payload) {
      commit(SET_STATUS, "SUCCESS");

      try {
        const token = userService.getToken();
        const _pk = ncryptService._createPubToken();
        const headers = { Authorization: token, _pk };
        const response = await api.deleteItem(payload, headers);
        return response.data;
      } catch (error) {
        return ERROR_OBJ;
      }
    },
    async editItemPln({ commit }, payload) {
      commit(SET_STATUS, "SUCCESS");

      try {
        const token = userService.getToken();
        const _pk = ncryptService._createPubToken();
        const headers = { Authorization: token, _pk };
        const response = await api.editItem(payload, headers);
        return response.data;
      } catch (error) {
        return ERROR_OBJ;
      }
    },

    async addItemPln({ commit }, payload) {
      commit(SET_STATUS, "SUCCESS");

      try {
        const token = userService.getToken();
        const _pk = ncryptService._createPubToken();
        const headers = { Authorization: token, _pk };
        const response = await api.addItem(payload, headers);
        return response.data;
      } catch (error) {
        return ERROR_OBJ;
      }
    }
  },
};
